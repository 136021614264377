.f-ls-64 {
  letter-spacing: -0.09em !important;
}

.f-ls0 {
  letter-spacing: 0em !important;
}

.f-lse {
  letter-spacing: 0.016em !important;
}

.f-lsc-4 {
  letter-spacing: -0.04em !important;
}

.f-lsc-2 {
  letter-spacing: -0.02em !important;
}

.f-lse-2 {
  letter-spacing: 0.02em !important;
}

/* 100;400;700;900 */

.f1t {
  font-family: Roboto;
  font-style: normal;
  font-weight: 100;
  font-size: 0.6rem;
  padding: 0px;
  margin: 0px;
  letter-spacing: -0.04em;
}

.f1 {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 0.6rem;
  padding: 0px;
  margin: 0px;
  letter-spacing: -0.04em;
}

.f1b {
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 0.6rem;
  padding: 0px;
  margin: 0px;
  letter-spacing: -0.04em;
}

.f2 {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  font-size: 0.6rem;
  padding: 0px;
  margin: 0px;
  letter-spacing: -0.04em;
}

.f2b {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  font-size: 0.6rem;
  padding: 0px;
  margin: 0px;
  letter-spacing: -0.04em;
}

.f05 {
  font-size: 0.5rem;
}
.f06 {
  font-size: 0.6rem;
}
.f07 {
  font-size: 0.7rem;
}
.f08 {
  font-size: 0.8rem;
}
.f09 {
  font-size: 0.9rem;
}
.f10 {
  font-size: 1rem;
}
.f11 {
  font-size: 1.1rem;
}
.f12 {
  font-size: 1.2rem;
  line-height: 1.4rem;
}
.f13 {
  font-size: 1.3rem;
}
.f14 {
  font-size: 1.4rem;
}
.f15 {
  font-size: 1.5rem;
  line-height: 1.7rem;
}
.f16 {
  font-size: 1.8rem;
}
.f18 {
  font-size: 1.8rem;
}
.f20 {
  font-size: 2rem;
}
.f25 {
  font-size: 2.5rem;
}
.f30 {
  font-size: 3rem;
}
.f40 {
  font-size: 4rem;
}
.f50 {
  font-size: 5rem;
}
.f60 {
  font-size: 6rem;
}

.lh08 {
  line-height: 0.8rem;
}

.lh10 {
  line-height: 1rem;
}

.lh12 {
  line-height: 1.2rem;
}

.lh14 {
  line-height: 1.4rem;
}

.t-UC {
  text-transform: uppercase;
}

.t-LC {
  text-transform: lowercase;
}

.ta-l {
  text-align: left;
}

.ta-c {
  text-align: center;
}

.ta-r {
  text-align: right;
}

.ta-e {
  text-align: end;
}
