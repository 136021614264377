/* BASE COLORS */

.bg-app {
  background: var(--app-bg);
}

.bg-app-dim {
  background: var(--app-bg-dim);
}

.bg-app-i {
  background: var(--app-bg-i);
}

.bg-app-nav {
  background: var(--app-bg-nav);
}

.bg-blur {
  backdrop-filter: blur(2px);
}

.txt-app {
  color: var(--app-txt);
}

.txt-app-i {
  color: var(--app-txt-i);
}

.txt-app-disabled {
  color: var(--app-txt-disabled);
}

.txt-app-dim {
  color: var(--app-txt-dim);
}

.txt-app-dimer {
  color: var(--app-txt-dimer);
}

.bg-white {
  background-color: var(--app-bg-whitest);
}

.bg-white-dim {
  background-color: #ffffff90;
}

.txt-white {
  color: var(--app-txt-whitest);
}

.bg-black {
  background-color: var(--app-bg-darkest);
}

.txt-black {
  color: var(--app-txt-darkest);
}

.bg-overlay-1 {
  background-color: var(--app-overlay-1);
}

.bg-overlay-2 {
  background-color: var(--app-overlay-2);
}

.bg-overlay-3 {
  background-color: var(--app-overlay-3);
}

.bg-app-separator {
  background: var(--app-separator);
}

.bb-app {
  border-bottom: 1px solid var(--app-separator);
}

.bt-app {
  border-top: 1px solid var(--app-separator);
}

.bds-1 {
  box-shadow: 0 8px 6px -6px var(--app-shadow);
}

.fds-1 {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.fds-2 {
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.5));
}

.fds-3 {
  filter: drop-shadow(0px 12px 12px rgba(0, 0, 0, 0.5));
}

.fds-3-i {
  filter: drop-shadow(0px -12px 12px rgba(0, 0, 0, 0.5));
}

.bg-transparent {
  background-color: transparent;
  background: transparent;
}

/* BUTTONS */

.bg-buttons {
  background: var(--button-bg);
}

.txt-buttons {
  color: var(--button-txt);
}

/* CALLS */

.bg-calls-overlay {
  background: var(--calls-overlay-bg);
}

.txt-calls {
  color: var(--calls-txt);
}

.bg-calls-cancel-overlay {
  background: var(--calls-cancel-overlay-bg);
}

.txt-calls-cancel {
  color: var(--calls-cancel-txt);
}

.bg-calls-cancel-yes {
  background: var(--call-cancel-yes-bg);
}

.txt-calls-cancel-yes {
  color: var(--call-cancel-yes-txt);
}

.bg-calls-cancel-no {
  background: var(--call-cancel-no-bg);
}

.txt-calls-cancel-no {
  color: var(--call-cancel-no-txt);
}

.bg-call-to-point {
  background: var(--call-to-point-bg);
}

.txt-call-to-point {
  color: var(--call-to-point-txt);
}

.bg-call-cash {
  background: var(--call-to-cash-bg);
}

.txt-call-cash {
  color: var(--call-to-cash-txt);
}

.bg-call-card {
  background: var(--call-to-card-bg);
}

.txt-call-card {
  color: var(--call-to-card-txt);
}

.bg-call-active {
  background: radial-gradient(transparent, var(--call-active-bg));
}

.txt-call-active {
  color: var(--call-active-txt);
}

.bg-call-confirmed {
  background: radial-gradient(transparent, var(--call-confirmed-bg));
}

.txt-call-confirmed {
  color: var(--call-confirmed-txt);
}

.bg-calls-popup-overlay {
  background: var(--calls-popup-overlay-bg);
}

.txt-calls-popup-overlay {
  color: var(--calls-popup-overlay-bg);
}

.txt-calls-popup {
  color: var(--calls-popup-txt);
}

/* NOTIFICATIONS */

.bg-success {
  background: var(--app-notification-success);
}

.bg-success-dim {
  background: var(--app-notification-success-dim);
}

.txt-success {
  color: var(--app-notification-success);
}

.bg-info {
  background: var(--app-notification-info);
}

.bg-info-dim {
  background: var(--app-notification-info-dim);
}

.txt-info {
  color: var(--app-notification-info);
}

.bg-warning {
  background: var(--app-notification-warning);
}

.bg-warning-dim {
  background: var(--app-notification-warning-dim);
}

.txt-warning {
  color: var(--app-notification-warning);
}

.bg-danger {
  background: var(--app-notification-danger);
}

.bg-danger-dim {
  background: var(--app-notification-danger-dim);
}

.txt-danger {
  color: var(--app-notification-danger);
}

.bg-info-light {
  background: var(--app-notification-info-light);
}

.bg-info-light-dim {
  background: var(--app-notification-info-light-dim);
}

.txt-info-light {
  color: var(--app-notification-info-light);
}

.txt-notifications {
  color: var(--app-notification-txt);
}

/* PRIMARY */

.bg-primary-success {
  background: var(--app-primary-success);
}

.bg-primary-success-dim {
  background: var(--app-primary-success-dim);
}

.txt-primary-success {
  color: var(--app-primary-success);
}

.bg-primary-info {
  background: var(--app-primary-info);
}

.bg-primary-info-dim {
  background: var(--app-primary-info-dim);
}

.txt-info {
  color: var(--app-primary-info);
}

.bg-primary-warning {
  background: var(--app-primary-warning);
}

.bg-primary-warning-dim {
  background: var(--app-primary-warning-dim);
}

.txt-primary-warning {
  color: var(--app-primary-warning);
}

.bg-primary-danger {
  background: var(--app-primary-danger);
}

.bg-primary-danger-dim {
  background: var(--app-primary-danger-dim);
}

.txt-primary-danger {
  color: var(--app-primary-danger);
}

.txt-primary {
  color: var(--app-primary-txt);
}

/* BASIC LOADING */

.bg-basic-loading {
  background: var(--basic-loading-bg);
}

.txt-basic-loading {
  color: var(--basic-loading-txt) !important;
}

/* BREAD CRUMB TRAIL */

.bg-bread-crumb-trail {
  background: var(--bread-crumb-trail-bg);
}

.txt-bread-crumb-trail {
  color: var(--bread-crumb-trail-txt);
}

/* SEARCH */

.bg-search {
  background: var(--search-bg);
}

.txt-search {
  color: var(--search-txt);
}

/* TAGS */

.bg-tags {
  background: var(--tags-bg);
}

.txt-tags {
  color: var(--tags-txt);
}

/* PRODUCT FULL */

.bg-product-full {
  background: linear-gradient(
    0deg,
    var(--product-full-bg) 0%,
    var(--product-full-bg-mid) 50%,
    var(--product-full-bg) 100%
  ) !important;
}

.txt-product-full {
  color: var(--product-full-txt);
}

/* PRODUCT */

.bg-product {
  background: var(--product-bg);
}

.txt-product {
  color: var(--product-txt);
}

.txt-product-dim {
  color: var(--product-txt-dim);
}

.txt-product-dimer {
  color: var(--product-txt-dimer);
}

.bg-product-price {
  background: var(--product-price-bg);
}

.txt-product-price {
  color: var(--product-price-txt);
}

.txt-add-item {
  color: var(--product-add-item);
}

/* TOPPINGS */

.bg-toppings-button-view {
  background: var(--app-toppings-button-bg);
}

.txt-toppings-button-view {
  color: var(--app-toppings-button-txt);
}

.bg-toppings {
  background: var(--app-toppings-bg);
}

.txt-toppings {
  color: var(--app-toppings-txt);
}

.bg-toppings-selected {
  background: var(--app-toppings-selected-bg);
}

.txt-toppings-selected {
  color: var(--app-toppings-selected-txt);
}

.bg-toppings-product-add {
  background: var(--app-toppings-button-add-bg);
}

.txt-toppings-product-add {
  color: var(--app-toppings-button-add-txt);
}

/* PLACEHOLDER */

.bg-placeholder {
  background: var(--app-placeholder-bg);
}

/* GRADIENTS */

.bg-gradient-1 {
  background: var(--app-gradient-color-1);
}

.bg-gradient-2 {
  background: var(--app-gradient-color-2);
}

.bg-gradient-3 {
  background: var(--app-gradient-color-3);
}

.bg-gradient-4 {
  background: var(--app-gradient-color-4);
}

.bg-gradient-5 {
  background: var(--app-gradient-color-5);
}

/* FADERS */

.bg-interface-fader-top {
  background: linear-gradient(
    0deg,
    var(--fader-start) 0%,
    var(--fader-top-overlay) 100%
  ) !important;
}

.bg-interface-fader-bottom {
  background: linear-gradient(
    0deg,
    var(--fader-botom-overlay) 0%,
    var(--fader-start) 100%
  ) !important;
}

.bg-interface-fader-top-glass {
  background: var(--fader-top-glass);
}

/* GDPR */

.bg-gdpr-button {
  background: var(--gdpr-button-bg);
}

.txt-gdpr-button {
  color: var(--gdpr-button-txt);
}

.bg-gdpr-container {
  background: var(--gdpr-container-bg);
}

.txt-gdpr-container {
  color: var(--gdpr-container-txt);
}

/* EXTRAS */

.bdf-2 {
  backdrop-filter: blur(2px);
}
