/* FLEX */

.f-c {
  display: flex;
}

.f-none {
  flex: none;
}

.f-w {
  flex-wrap: wrap;
}

.f-dirc {
  flex-direction: column;
}

.f-dircr {
  flex-direction: column-reverse;
}

.f-jcc {
  justify-content: center;
}

.f-jcsb {
  justify-content: space-between;
}

.f-jcfe {
  justify-content: flex-end;
}

.f-aic {
  align-items: center;
}

.f-aife {
  align-items: flex-end;
}

.f-asc {
  align-self: center;
}

.f-asfe {
  align-self: flex-end;
}

.f-0 {
  flex: 0;
}

.f-1 {
  flex: 1;
}

.f-15 {
  flex: 1.5;
}

.f-2 {
  flex: 2;
}

.ib-c {
  display: inline-block;
}

.mc {
  margin: 0 auto;
}

.oflows {
  overflow: scroll;
}
.oflows-x {
  overflow-x: scroll;
}
.oflows-y {
  overflow-y: scroll;
}

.oflowh {
  overflow: hidden;
}
.oflowh-x {
  overflow-x: hidden;
}
.oflowh-y {
  overflow-y: hidden;
}

.br-f {
  border-radius: 100%;
}

.minH25 {
  min-height: 25px;
}

.minH50 {
  min-height: 50px;
}

.minH100 {
  min-height: 100px;
}

.minH150 {
  min-height: 150px;
}

.minH200 {
  min-height: 200px;
}

.minH300 {
  min-height: 300px;
}

.H100v {
  height: 100vh;
}

.minH100v {
  min-height: 100vh;
}

.maxW100v {
  max-width: 100vw;
}

.maxH100v {
  max-height: 100vh;
}

.minWH100v {
  min-width: 100vw;
  min-height: 100vh;
}

.WH100 {
  width: 100%;
  height: 100%;
}

.WH100v {
  width: 100vw;
  height: 100vh;
}

.H100 {
  height: 100%;
}

.W100 {
  width: 100%;
}

.H160 {
  height: 160px;
  max-height: 160px;
  min-height: 160px;
}

.W600 {
  width: 600px;
  max-width: 600px;
  min-width: 600px;
}

.p-rel {
  position: relative;
}

.p-fix {
  position: fixed;
}

.p-abs {
  position: absolute;
}

.p-full {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.p-full-16 {
  top: 16px;
  right: 16px;
  bottom: 16px;
  left: 16px;
}

.p-bottom {
  bottom: 0;
  right: 0;
  left: 0;
}

.p-top {
  top: 0;
  right: 0;
  left: 0;
}

.p-top-16 {
  top: 16px;
  right: 16px;
  left: 16px;
}

.p-top-left {
  top: 0;
  left: 0;
}

.p-top-right {
  top: 0;
  right: 0;
}

.p-bottom-left {
  bottom: 0;
  left: 0;
}

.p-bottom-right {
  bottom: 0;
  right: 0;
}

.p-bottom-16 {
  bottom: 16px;
  right: 16px;
  left: 16px;
}

.l0 {
  z-index: 0;
}
.l1 {
  z-index: 1;
}
.l2 {
  z-index: 2;
}
.l3 {
  z-index: 3;
}
.l4 {
  z-index: 4;
}
.l5 {
  z-index: 5;
}
.l6 {
  z-index: 6;
}
.l7 {
  z-index: 7;
}
.l8 {
  z-index: 8;
}
.l9 {
  z-index: 9;
}
.l10 {
  z-index: 10;
}
.l11 {
  z-index: 11;
}
.l12 {
  z-index: 12;
}

.br-2 {
  border-radius: 2px;
}

.br-4 {
  border-radius: 4px;
}

.br-8 {
  border-radius: 8px;
}

.br-16 {
  border-radius: 16px;
}

.box-bb {
  box-sizing: border-box;
}

.r-45 {
  transform: rotate(45deg);
}

.s-8-i {
  width: 8px !important;
  height: 8px !important;
}

.s-12-i {
  width: 12px !important;
  height: 12px !important;
}

.s-16-i {
  width: 16px !important;
  height: 16px !important;
}

.s-24-i {
  width: 24px !important;
  height: 24px !important;
}

.s-32-i {
  width: 32px !important;
  height: 32px !important;
}

.s-64-i {
  width: 64px !important;
  height: 64px !important;
}
