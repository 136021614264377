/* MIXEZ */

.pc-lr {
  padding: 0 var(--d8);
}

.mc-lr {
  margin: 0 var(--d8);
}

.pc-lr-2x {
  padding: 0 var(--d16);
}

.mc-lr-2x {
  margin: 0 var(--d16);
}

.mp0 {
  margin: 0;
  padding: 0;
}

.m0 {
  margin: 0;
}

.p0 {
  padding: 0;
}

/* PADDINGS */

.p-2 {
  padding: var(--d2);
}
.p-4 {
  padding: var(--d4);
}
.p-8 {
  padding: var(--d8);
}
.p-16 {
  padding: var(--d16);
}
.p-32 {
  padding: var(--d32);
}

.t-2 {
  padding-top: var(--d2);
}
.pt-4 {
  padding-top: var(--d4);
}
.pt-8 {
  padding-top: var(--d8);
}
.pt-16 {
  padding-top: var(--d16);
}
.pt-32 {
  padding-top: var(--d32);
}

.pr-2 {
  padding-right: var(--d2);
}
.pr-4 {
  padding-right: var(--d4);
}
.pr-8 {
  padding-right: var(--d8);
}
.pr-16 {
  padding-right: var(--d16);
}
.pr-32 {
  padding-right: var(--d32);
}

.pb-2 {
  padding-bottom: var(--d2);
}
.pb-4 {
  padding-bottom: var(--d4);
}
.pb-8 {
  padding-bottom: var(--d8);
}
.pb-16 {
  padding-bottom: var(--d16);
}
.pb-32 {
  padding-bottom: var(--d32);
}

.pl-2 {
  padding-left: var(--d2);
}
.pl-4 {
  padding-left: var(--d4);
}
.pl-8 {
  padding-left: var(--d8);
}
.pl-16 {
  padding-left: var(--d16);
}
.pl-32 {
  padding-left: var(--d32);
}

/* MARGINGS */

.m-2 {
  margin: var(--d2);
}
.m-4 {
  margin: var(--d4);
}
.m-8 {
  margin: var(--d8);
}
.m-16 {
  margin: var(--d16);
}
.m-32 {
  margin: var(--d32);
}

.mt-2 {
  margin-top: var(--d2);
}
.mt-4 {
  margin-top: var(--d4);
}
.mt-8 {
  margin-top: var(--d8);
}
.mt-16 {
  margin-top: var(--d16);
}

.mt-24 {
  margin-top: var(--d24);
}

.mt-32 {
  margin-top: var(--d32);
}
.mt-64 {
  margin-top: var(--d64);
}

.mr-2 {
  margin-right: var(--d2);
}
.mr-4 {
  margin-right: var(--d4);
}
.mr-8 {
  margin-right: var(--d8);
}
.mr-16 {
  margin-right: var(--d16);
}
.mr-32 {
  margin-right: var(--d32);
}

.mb-2 {
  margin-bottom: var(--d2);
}
.mb-4 {
  margin-bottom: var(--d4);
}
.mb-8 {
  margin-bottom: var(--d8);
}
.mb-16 {
  margin-bottom: var(--d16);
}
.mb-32 {
  margin-bottom: var(--d32);
}
.mb-64 {
  margin-bottom: var(--d64);
}

.ml-2 {
  margin-left: var(--d2);
}
.ml-4 {
  margin-left: var(--d4);
}
.ml-8 {
  margin-left: var(--d8);
}
.ml-16 {
  margin-left: var(--d16);
}
.ml-32 {
  margin-left: var(--d32);
}
