.menu-item {
  /* margin: 20px; */
  user-select: none;
  cursor: pointer;
  border: none;
}

.order-confirm-type input[type='radio'] {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  cursor: pointer;
}

.order-confirm-type input[type='radio']:after {
  position: relative;
  height: 8px;
  width: 8px;
  border-radius: 100%;
  background: #fff;
  content: '';
}

.order-confirm-type input[type='radio']:checked:after {
  height: 8px;
  width: 8px;
  background-color: #0f0;
  border-radius: 100%;
  content: '';
}

.video-container {
  position: absolute;
  margin: auto;
  width: 100%;
  height: 100%;

  overflow: hidden;
  clip-path: 0 0;
  transform: translatez(0);
}

.video-centered {
  transform: translatez(0);
  position: absolute;
  top: -100%;
  bottom: -100%;
  left: -100%;
  right: -100%;

  margin: auto;

  min-width: 100%;
  min-height: 100%;
}

.cls-1 {
  fill: #fff;
  stroke-width: 0px;
}
