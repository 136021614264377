.pe-none {
  pointer-events: none !important;
}

.pe-all {
  pointer-events: all !important;
}

.no-border {
  border: none;
}

.no-outline {
  outline: 0;
}

.no-select {
  user-select: none;
}

.cursor-default {
  cursor: default;
}

.of-cover {
  object-fit: cover;
}

.of-contain {
  object-fit: contain;
}

.td-u {
  text-decoration: underline;
}

.wb-break {
  word-break: break-word;
}

